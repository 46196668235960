.sar-character-content {
    min-height: 180px;
    text-align: left;

    img {
        float: left;
        max-height: 150px;
        max-width: 150px;
        margin: 10px;
    }

    p {
        width: calc(100% - 180px);
        margin-left: auto;
    }
}

.sar-chase-breed-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    img {
        margin: 0 auto 6px;
        max-height: 120px;
        max-width: 120px;
    }
}

.sar-character-name {
    font-weight: bold;
}

@media screen and (max-width: 960px) {

    .sar-character-content {
        min-height: 200px;
        text-align: center;

        img {
            float: unset;
        }

        p {
            width: 95%;
            margin-right: auto;
        }
    }

    .sar-chase-breed-content {
        img {
            max-height: 80px;
            max-width: 80px;
        }
    }
}