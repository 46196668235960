.comic-controls {
    margin: 20px 20%;
    padding: 20px;
    text-align: center;

    ul {
        display: flex;
        list-style: none;

        li {
            flex: 1;

            .active-button {
                img {
                    height: 60px;
                }
            }

            .disabled-button {
                height: 60px;
                opacity: 0.5;
                filter: alpha(opacity=50); /* IE8 */
            }
        }
    }
}

@media screen and (max-width: 960px) {

    .comic-controls {
        margin: 20px 1%;

        ul {
            padding-left: 0;
        }
    }
}