.page-404 {
    text-align: center;
}

.side-text {
    padding: 30px 250px 0 30px;
}

.silhouette-missing {
    min-height: 400px;
    background-size: 220px;
    background-image: url("https://cdn.chasethefox.com/m/chase/assets/chase_confused.png");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 100%;
}

@media screen and (max-width: 960px) {
    .silhouette {
        height: 240px;
    }
}