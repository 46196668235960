.scheme-switch-wrapper {
    display: flex;
    align-items: center;
}

.scheme-switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;

    input {
        display: none;
    }
}

.scheme-slider {
    background-color: var(--edge-color, gray);
    border: 2px var(--background-color, lightblue) solid;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: .4s;

    &:before {
        background-color: var(--background-color, grey);
        position: absolute;
        height: 26px;
        width: 26px;
        text-align: center;
        font-size: 16px;
        line-height: 1.6;
        content: "🌞";
        bottom: 2px;
        left: 2px;
        transition: .4s;
    }

    &.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
}

input:checked + .scheme-slider {
    background-color: var(--background-color, darkblue);
    border: 2px var(--edge-color, grey) solid;

    &:before {
        background-color: var(--content-box-color, black);
        content: "🌙";
        transform: translateX(42px);
    }
}

@media screen and (max-width: 960px) {
    .scheme-switch {
        height: 24px;
        width: 40px;
    }

    .scheme-slider {
        border: 1px var(--background-color, lightblue) solid;

        &:before {
            font-size: 14px;
            height: 18px;
            width: 18px;
            line-height: 1.3;
        }
    }

    input:checked + .scheme-slider {
        background-color: var(--background-color, darkblue);
        border: 2px var(--edge-color, grey) solid;

        &:before {
            transform: translateX(30px);
        }
    }
}