.fur-gen-result {
    height: 550px;
}

.fur-gen-result-waiting {
    height: fit-content;

    img {
        display: block;
        margin: 100px auto;
        height: 200px;
    }
}

.fur-gen-result-display {
    min-height: 400px;
    height: fit-content;

    div {
        .fur-gen-result-message {
            min-height: 60px;
            width: 90%;
            margin: 6px auto;
        }

        .fur-gen-result-tweet {
            height: fit-content;
            width: fit-content;
            margin: 0 auto;
            padding-left: 0;
            list-style: none;

            li {
                padding: 8px 0;
            }
        }
    }
}

.fur-gen-result-image {
    display: block;
    height: 200px;
    margin: 30px auto 6px;
}

@media screen and (max-width: 960px) {

    .fur-gen-result {
        height: 750px;
    }

    .fur-gen-result-waiting {
        img {
            margin: auto;
            height: fit-content;
            width: 90%;
        }
    }

    .fur-gen-result-display {
        div {
            .result-tweet {
                padding: 12px 6px;
            }
        }
    }

    .fur-gen-result-image {
        height: 160px;
        margin: 100px auto;
    }

    .fur-gen-result-tweet {
        li {
            padding: 16px 0;
        }
    }

}