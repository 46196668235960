#header-text {
    position: absolute;
    left: 220px;
    top: 20px;

    a {
        text-decoration: none;

        img {
            height: 60px;
        }
    }
}

@media screen and (max-width: 960px) {

    #header-text {
        left: 100px;
        top: 8px;

        a {
            img {
                height: 32px;
            }
        }
    }
}