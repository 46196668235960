.casarok-common-table {
    display: block;
    margin: 12px auto;
    width: 50%;
    border-spacing: 0;

    tr {
        width: 100%;
    }

    th {
        font-weight: bold;
        padding: 4px;
        border: 1px solid var(--primary-text, black);
    }

    td {
        padding: 4px 20px;
        margin: 0;
        width: 30%;
        min-width: 60px;
        text-align: center;
        border: 1px solid var(--primary-text, black);
    }
}

.last-column-wide {
    td:last-child {
        width: 60%;
    }
}

.example-table {
    width: 80%;
}

.numeral-common-table {
    display: block;
    margin: 12px auto;
    width: 90%;
    border-spacing: 0;

    tr {
        width: 100%;
    }

    th {
        font-weight: bold;
        padding: 4px;
        border: 1px solid var(--primary-text, black);
    }

    td {
        padding: 4px 20px;
        margin: 0;
        width: 15%;
        min-width: 60px;
        text-align: center;
        border: 1px solid var(--primary-text, black);

        &:first-child {
            width: 70%
        }
    }
}

@media screen and (max-width: 960px) {
    .casarok-common-table {
        width: 100%;

        td {
            padding: 4px;
        }
    }

    .last-column-wide {
        td:last-child {
            width: 30%;
        }
    }

    .numeral-common-table {
        width: 100%;

        th:last-child {
            display: none;
        }

        td {
            padding: 2px;
            width: 10%;
            min-width: 20px;

            &:first-child {
                width: 10%
            }

            &:last-child {
                display: none;
            }
        }
    }
}