.badge {
    display: inline-block;
    position: relative;
    object-fit: contain;
    z-index: 2;

    img {
        height: 60px;
        width: 60px;
    }

    span {
        position: absolute;
        top: -12px;
        right: -12px;
        font-size: 36px;
        cursor: default;
        user-select: none;
        text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.3);
    }

    &.star-level-0 {
        img {
            filter: saturate(0);
        }

        span {
            display: none;
        }
    }

    &.star-level-1 {
        span {
            color: sienna;
        }
    }

    &.star-level-2 {
        span {
            color: silver;
        }
    }

    &.star-level-3 {
        span {
            color: goldenrod;
        }
    }
}

@media screen and (max-width: 960px) {
}