.fanart-submit-description {
    width: 90%;
    margin: 6px auto;
}

.fanart-submit-form {
    width: 90%;
    margin: 32px auto;
}

@media screen and (max-width: 960px) {

}