.color-preview {

    .color-preview-name {
        cursor: default;
        text-decoration: dotted underline;
    }

    .color-preview-anchor {
        position: relative;
        display: inline-block;
        width: 8px;
        padding-left: 2px;

        .color-preview-swatch {
            display: inline-block;
            border: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 0;
            width: 0;
            margin: 0 2px;

            .color-preview-swatch-color {
                display: inline-block;
                position: relative;
                bottom: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                z-index: -1;

                .color-preview-swatch-hexcode {
                    color: black;
                    position: absolute;
                    opacity: 0;
                }
            }

            .color-preview-swatch-indicator {
                position: absolute;
                bottom: -2px;
                right: 0;
                width: 0;
                height: 0;
                border-bottom: 8px solid rgb(60, 60, 60);
                border-left: 8px solid transparent;
            }
        }
    }

    &:hover {
        .color-preview-name {
            text-decoration: underline;
        }

        .color-preview-anchor {
            .color-preview-swatch {
                animation: form-border 0.5s ease-in-out;
                border: 4px rgba(60, 60, 60, 1) solid;
                border-radius: 8px;
                height: 100px;
                width: 100px;
                top: 0;
                left: 0;

                .color-preview-swatch-color {
                    animation: gradually-visible 0.5s ease-in-out;
                    display: inline-block;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    opacity: 1;
                    z-index: 1;

                    .color-preview-swatch-hexcode {
                        animation: gradually-visible 0.5s ease-in-out;
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.5);
                        bottom: 0;
                        left: 0;
                        font-size: 14pt;
                        font-weight: normal;
                        font-family: monospace;
                        padding: 0 2px;
                        opacity: 1;
                    }
                }

                .color-preview-swatch-indicator {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }

    @keyframes form-border {
        0% { width: 0; height: 0; border: 4px rgba(60, 60, 60, 0) solid; border-radius: 8px; }
        100% { width: 100px; height: 100px; border: 4px rgba(60, 60, 60, 1) solid; border-radius: 8px; }
    }
}

@media screen and (max-width: 960px) {

    .color-preview {

        .color-preview-anchor {
            padding-left: 0;
            width: 0;
        }

        .color-preview-swatch-indicator {
            border-bottom: 0;
            border-left: 0;
            opacity: 0;
        }
    }

    .color-preview:hover {

        .color-preview-anchor {
            position: absolute;
            left: 50%;
            padding-left: 0;
            margin-top: 8px;

            .color-preview-swatch {
                animation: form-border-mobile 0.5s ease-in-out;
                border: 4px rgba(60, 60, 60, 1) solid;
                border-radius: 8px;
                height: 100px;
                width: 200px;
                top: 20pt;
                left: calc(50% - 110px);
                margin: 100% 2px;

                .color-preview-swatch-color {
                    animation: gradually-visible 0.5s ease-in-out;
                    display: inline-block;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    opacity: 1;

                    .color-preview-swatch-hexcode {
                        animation: gradually-visible 0.5s ease-in-out;
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.5);
                        bottom: 0;
                        left: 0;
                        font-size: 16pt;
                        padding: 0 2px;
                        opacity: 1;
                    }
                }

                .color-preview-swatch-indicator {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }

    @keyframes form-border-mobile {
        0% { bottom: 0; right: 0; height: 0; width: 200px; border: 4px rgba(60, 60, 60, 0) solid; border-radius: 8px; }
        100% { top: 20pt; left: calc(50% - 110px); height: 100px; width: 200px; border: 4px rgba(60, 60, 60, 1) solid; border-radius: 8px; }
    }
}

@keyframes gradually-visible {
    0% { opacity: 0 }
    100% { opacity: 1 }
}