.comic-commentary {
    width: 70%;
    margin: 30px auto;
    color: black;

    img {
        display: inline-block;
        vertical-align: top;
        max-height: 120px;
        border: 4px solid var(--speech-bubble-border, black);
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
    }
}

.chase-comment {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    min-height: 120px;
    width: calc(70% - 440px);
    margin-left: 70px;
    background-color: var(--speech-bubble-fill, white);
    border: 4px solid var(--speech-bubble-border, black);
    border-radius: 40px;
    text-align: center;
    font-size: 20pt;
    font-weight: bold;

    span {
        display: inline-block;
        width: 90%;
        padding: 0 5%;
        text-align: center;
        position: absolute;
        left: 0;
        margin-top: 3%;
    }
}

.tail-border {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 60px solid transparent;
    border-top: 40px solid var(--speech-bubble-border, black);
    position: absolute;
    top: 45%;
    left: -62px;
    transform: rotate(186deg);
}

.tail-inner {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 60px solid transparent;
    border-top: 40px solid var(--speech-bubble-fill, white);
    position: absolute;
    top: calc(45% - 2px);
    left: -50px;
    transform: rotate(186deg);
}

@media screen and (max-width: 960px) {

    .comic-commentary {
        width: 90%;
        margin: 8px auto;

        img {
            display: inline-block;
            vertical-align: bottom;
            max-height: 80px;
            border: 2px solid var(--speech-bubble-border, black);
            margin-top: 100px;
        }
    }

    .chase-comment {
        font-size: 16pt;
        min-height: 180px;
        width: calc(90% - 180px);
        margin-left: 40px;
        border: 2px solid var(--speech-bubble-border, black);
        border-radius: 20px;

        span {
            width: 95%;
            padding: 0 2.5%;
            margin-top: 2.5%;
        }
    }

    .tail-border {
        border-left: 2px solid transparent;
        border-right: 40px solid transparent;
        border-top: 20px solid var(--speech-bubble-border, black);
        top: 70%;
        left: -42px;
    }

    .tail-inner {
        border-left: 2px solid transparent;
        border-right: 40px solid transparent;
        border-top: 20px solid var(--speech-bubble-fill, white);
        top: calc(70% - 1px);
        left: -34px;
    }
}