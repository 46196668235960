/* ---------------------------------------------------------------------
Styles for dynamic components
---------------------------------------------------------------------- */

.spaced {
    li {
        margin-bottom: 18px;
    }
}

.switch-hidden {
    display: none;
}

.switch-visible {
    display: block;
    margin: 0 auto;
}

.element-image {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
}

.magic-element-header {
    display: inline-block;
    height: 30px;
    vertical-align: middle;
}

.magic-element-image {
    height: 24px;
    vertical-align: top;
}

.spell-image {
    display: inline-block;
    height: 36px;
    vertical-align: middle;
}

.map-image {
    width: 48%;
    display: inline-block;
}

.races-image {
    background-size: 160px;
    background-repeat: no-repeat;
    height: 160px;
    width: 160px;
    margin: 10px 0 10px 40px;
    float: right;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.races-text {
    min-height: 180px;
}

.character-lineup {
    margin: 20px;
    text-align: center;

    div {
        display: inline-block;
        height: 180px;
        width: 180px;
        margin: 0 8px 60px;
        text-align: center;

        a {
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                margin-bottom: 12px;
            }
        }
    }
}

.character {
    height: 200px;
}

.myth {
    font-style: italic;
}

/* ---------------------------------------------------------------------
Styles for the element chart
--------------------------------------------------------------------- */

.elements-chart {
    width: 75%;
    background-color: var(--content-box-color, white);
    border: lightgrey 1pt solid;
    padding: 8%;
    margin: 0 auto;

    div {
        display: inline-block;
        position: relative;
        height: 30%;
        width: 40%;

        span {
            position: absolute;
            width: 100%;
            font: 10pt bold Arial sans-serif;
        }

        #divine-school {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 102%;
            width: 105%;
            background-color: rgba(150, 150, 150, 0.25);
            border: grey 1pt solid;

            span {
                padding-left: 2%;
                text-align: left;
                color: grey;
            }
        }
    }

    #arcane-school {
        float: left;
        margin-left: 8.5%;
        background-color: rgba(150, 255, 255, 0.25);
        border: blue 1pt solid;

        span {
            padding-left: 2%;
            text-align: left;
            color: blue;
        }
    }

    #elemental-school {
        float: right;
        margin-right: 8.5%;
        background-color: rgba(150, 255, 150, 0.25);
        border: green 1pt solid;

        span {
            right: 2%;
            text-align: right;
            color: green;
        }
    }

    #metaphysical-school {
        float: bottom;
        margin: 0.5% 30% 0;
        background-color: rgba(255, 150, 150, 0.25);
        border: red 1pt solid;

        span {
            bottom: 2%;
            color: red;
        }
    }
}

.element-chart-image {
    width: 20%;
}

.element-top-left {
    float: left;
    margin-left: 15%;
    margin-top: 15%;
    margin-bottom: 10%;
}

.element-top-right {
    float: right;
    margin-right: 15%;
    margin-top: 15%;
    margin-bottom: 10%;
}

.element-bottom-left {
    float: left;
    margin-left: 15%;
    margin-bottom: 15%;
    margin-top: 15%;
}

.element-bottom-right {
    float: right;
    margin-right: 15%;
    margin-bottom: 15%;
    margin-top: 15%;
}

@media screen and (max-width: 960px) {

    /* ---------------------------------------------------------------------
    Styles for dynamic components in mobile
    ---------------------------------------------------------------------- */

    .map-image {
        width: 50%;
    }

    .world-button {
        font-size: 16px;
        padding: 8px;
    }

    .races-text {
        min-height: 180px;
    }

    .character-lineup {
        margin-bottom: 0;

        div {
            height: 150px;
            width: 150px;
            margin: 0 2px 32px;

            a {
                margin-bottom: 8px;

                img {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .character {
        height: 180px;
    }

    /* ---------------------------------------------------------------------
    Styles for the element chart on mobile
    --------------------------------------------------------------------- */

    .elements-chart {
        width: 80%;
        padding: 10%;

        div {
            span {
                top: -18pt;
                font-size: 16pt;
            }

            #divine-school {
                span {
                    top: 50%;
                    left: -64px;
                }
            }
        }

        #metaphysical-school {
            span {
                top: 102%;
                text-align: center;
            }
        }
    }
}