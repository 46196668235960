#intro {
    margin: 0 270px 60px 20px;
    line-height: 1.8;
}

.intro-wave {
    float: right;
    height: 180px;
    padding-right: 60px;
}

.site-intro {
    object-fit: contain;
    padding: 16px 0 !important;
}

.status {
    height: 300px;

    table {
        width: 80%;
        margin: 0 auto 20px;
        border-spacing: 20px;
    }
}

.news {
    height: 300px;

    a {
        display: block;
        height: fit-content;
        width: fit-content;
        text-align: center;
        margin: 0 auto;

        img {
            display: inline;
            vertical-align: bottom;
            width: 100%;
            max-width: 900px;
            min-width: 300px;
        }
    }
}

@media screen and (max-width: 960px) {

    #intro {
        margin: 20px;
        line-height: 1.6;
    }

    .intro-wave {
        display: block;
        float: none;
        margin: 0 auto;
        height: 200px;
        padding-right: 0;
    }

    .site-intro {
        height: fit-content;
    }

    .status {
        table {
            width: 95%;
            margin: 0 auto 20px;
            border-spacing: 10px;
        }
    }

    .news {
        a {
            img {
                max-width: 100%;
                min-width: 250px;
            }
        }
    }
}