.tf-gen-intro {
    min-height: 200px;
    margin: 4px auto 0;
    padding: 0 5%;

    p {
        display: inline-block;
        width: calc(90% - 200px);
        vertical-align: top;
    }

    img {
        float: right;
        height: 200px;
        width: 200px;
    }
}

.quick-tf-button {
    display: block;
    border: 1px solid black;
    width: 400px;
    padding: 4px;
    border-radius: 4px;
    margin-left: calc(50% - 400px);
    text-align: center;
    background-color: lightgray;
    text-decoration: none;
    color: black;

    &:hover {
        background-color: white;
        color: black;
        text-decoration: none;
    }
}

.tf-form-col-labels {
    display: inline-block;
    padding: 8px;
    width: 250px;

    label {
        margin-right: 24px;
    }
}

.tf-form-col-content {
    display: inline-block;
    padding: 8px;
    width: 320px;
}

.tf-form-buttons {
    padding: 8px;
    width: 500px;

    input {
        margin: 4px;
    }
}

.tf-color-set-note {
    padding: 0 8px;
    font-size: 10pt;
}

@media screen and (max-width: 960px) {

    .tf-gen-intro {
        p {
            display: block;
            width: 100%;
        }

        img {
            display: block;
            float: none;
            margin: 0 auto;
            height: 160px;
            width: 160px;
        }
    }

    .quick-tf-button {
        width: 220px;
        margin: 0 auto;
        text-align: center;
    }

    .tf-form-col-labels {
        display: block;
        margin-top: 12px;
        padding: 4px;
        width: 100%;

        label {
            margin-right: 0;
        }
    }

    .tf-form-col-content {
        display: block;
        padding: 4px;
        width: fit-content;

        select {
            margin: 0 4px;
            font-size: 16pt;
        }
    }

    .tf-form-buttons {
        display: block;
        padding: 4px;
        width: 100%;

        input {
            display: block;
            margin: 16px 4px;
            font-size: 16pt;
        }
    }

    .tf-color-set-note {
        display: block;
        font-size: 16pt;
        padding-left: 4px;
        padding-top: 8px;
    }
}