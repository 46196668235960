/* ---------------------------------------------------------------------
Based on the speech bubble pattern in PURE CSS SPEECH BUBBLES by Nicolas Gallagher
  - http://nicolasgallagher.com/pure-css-speech-bubbles/
  - http://nicolasgallagher.com
  - http://twitter.com/necolas
--------------------------------------------------------------------- */

.speech-bubble {
    background: var(--speech-bubble-fill, white);
    border: 2px solid var(--speech-bubble-border, black);
    color: black;
    margin: 20px;
    padding-top: 2%;
    text-align: center;
    border-radius: 60px;

    div {
        padding-top: 0.5%;

        span {
            padding: 20px 6% 0 6%;
            font-size: 36px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: bold;
        }
    }
}

.tail-right {
    &:after {
        content: "";
        position: relative;
        bottom: -17px;
        left: calc(100% - 150px);
        border-width: 18px 18px 0;
        border-style: solid;
        border-color: var(--speech-bubble-fill, white) transparent;
        display: block;
        width: 0;
    }

    div:after {
        content: "";
        position: relative;
        bottom: -38px;
        left: calc(100% - 152px);
        border-width: 20px 20px 0;
        border-style: solid;
        border-color: var(--speech-bubble-border, black) transparent;
        display: block;
        width: 0;
    }
}

.tail-left {
    &:after {
        content: "";
        position: relative;
        bottom: -17px;
        left: 150px;
        border-width: 18px 18px 0;
        border-style: solid;
        border-color: var(--speech-bubble-fill, white) transparent;
        display: block;
        width: 0;
    }

    div:after {
        content: "";
        position: relative;
        bottom: -38px;
        left: 148px;
        border-width: 20px 20px 0;
        border-style: solid;
        border-color: var(--speech-bubble-border, black) transparent;
        display: block;
        width: 0;
    }
}

.tail-middle {
    &:after {
        content: "";
        position: relative;
        bottom: -17px;
        left: 50%;
        border-width: 18px 18px 0;
        border-style: solid;
        border-color: var(--speech-bubble-fill, white) transparent;
        display: block;
        width: 0;
    }

    div:after {
        content: "";
        position: relative;
        bottom: -38px;
        left: calc(50% - 2px);
        border-width: 20px 20px 0;
        border-style: solid;
        border-color: var(--speech-bubble-border, black) transparent;
        display: block;
        width: 0;
    }
}

@media screen and (max-width: 960px) {

    .speech-bubble {
        font-size: 20px;
        font-weight: bold;
        margin: 12px;
        border-radius: 300px;

        div {
            padding: 20px 2% 0 2%;

            span {
                padding: 20px 2% 0 2%;
            }
        }
    }

    .tail-middle {
        &:after {
            bottom: -17px;
        }

        div:after {
            bottom: -38px;
        }
    }
}
