.pokemon-player-list {
    margin: 0 auto;
    width: 90%;

    ul {
        display: inline-block;
        list-style: none;
        width: 100%;
        padding-left: 0;
        columns: 2;

        li {
            width: fit-content;
            margin: 2px auto;
        }
    }
}