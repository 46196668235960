.donation-tracker-table {
    display: block;
    margin: 12px auto;
    width: fit-content;
    border-spacing: 0;

    tr {
        background-color: var(--content-box-color, white);
        width: 100%;

        &:nth-child(even) {
            background-color: var(--content-box-color-secondary, silver);
        }
    }

    th {
        background-color: var(--content-box-color-secondary, silver);
        font-weight: bold;
        padding: 4px;
        border-bottom: 2px solid var(--primary-text, black);
    }

    td {
        padding: 4px 20px;
        margin: 0;
        width: 80%;
        min-width: 60px;
        text-align: center;

        &:first-child {
            text-align: left;
        }
    }
}

@media screen and (max-width: 960px) {
    .donation-tracker-table {
        tr {
            td {
                span {
                    display: inline-block;
                    max-width: 120px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}