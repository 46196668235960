.commission-type-list {
    margin: 12px 20px;
    list-style: none;
    padding-left: 0;
}

.commission-example-image {
    background-size: 160px;
    background-repeat: no-repeat;
    height: 150px;
    width: 200px;
    margin: 10px 40px 10px 0;
    float: left;
}

.commission-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.commissions-text {
    min-height: 160px;
}

@media screen and (max-width: 960px) {

    .commission-example-image {
        float: none;
        margin: 10px auto;
    }

    .commissions-text {
        min-height: 100px;
    }
}