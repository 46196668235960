.world-book {
    display: flex;
    position: relative;
    height: inherit;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: var(--content-book-cover, lightblue);
}

.world-book-page {
    flex: 1;
    min-height: 640px;
    background-color: var(--content-book-page, white);
    box-shadow: rgba(0, 0, 0, 0.5) 0 1em 3em;
    line-height: 1.25;
    padding: 1em 1em 3em 1em;
    width: 44%;
}

.world-link {
    margin-bottom: 4px;
    font-weight: bold;
    color: var(--primary-text, black);
}

.world-navigation {
    display: flex;
    position: absolute;
    bottom: 30px;
    right: 30px;
    float: right;
    font-weight: bold;
    text-align: right;

    a {
        padding-left: 4px;
    }

    span {
        padding-left: 4px;
    }
}

#page-left {
    border-right: 1px solid var(--secondary-text, #999999);
}

#page-right {
    border-left: 1px solid var(--secondary-text, #999999);
}

/* ---------------------------------------------------------------------
Adds glow to an anchor
---------------------------------------------------------------------- */

a.glowing {
    color: var(--primary-text, black);
    text-decoration: none;

    &:hover {
        color: black;
        animation: glow 1s ease-in-out infinite alternate;
        -webkit-animation: glow 1s ease-in-out infinite alternate;
        -moz-animation: glow 1s ease-in-out infinite alternate;
    }
}

@keyframes glow {
    from {
        text-shadow: 0 0 3px #fff, 0 0 4px #fff, 0 0 5px #86f6d2, 0 0 6px #86f6d2, 0 0 7px #86f6d2, 0 0 8px #86f6d2, 0 0 9px #86f6d2;
    }
    to {
        text-shadow: 0 0 4px #fff, 0 0 5px #2ca9ec, 0 0 6px #2ca9ec, 0 0 7px #2ca9ec, 0 0 8px #2ca9ec, 0 0 9px #2ca9ec, 0 0 10px #2ca9ec;
    }
}

@media screen and (max-width: 960px) {

    .world-book {
        display: block;
        width: 90%;
        margin: 20px auto;
        padding: 4px;
        background-color: var(--content-scroll-border-color, goldenrod);
        border: var(--content-box-color, white) 12px solid;
        box-shadow: rgba(0, 0, 0, 0.5) 0 1em 2.5em;
    }

    .world-book-page {
        flex: unset;
        min-height: 100px;
        box-shadow: unset;
        padding: 1em;
        width: auto;
        margin: 0 auto;
    }

    .world-navigation {
        float: unset;
        display: block;
        bottom: 10px;

        a {
            display: inline-block;
            padding-left: 8px;
        }

        span {
            padding-left: 8px;
        }
    }

    #page-left {
        border-right: unset;
    }

    #page-right {
        border-left: unset;
        padding-bottom: 5em;
    }

    /* ---------------------------------------------------------------------
    Glow effect for mobile
    ---------------------------------------------------------------------- */

    a.glowing {
        color: black;
        text-decoration: underline;
        animation: glow 4s ease-in-out infinite alternate;
        -webkit-animation: glow 4s ease-in-out infinite alternate;
        -moz-animation: glow 4s ease-in-out infinite alternate;

        &:hover {
            animation: glow 4s ease-in-out infinite alternate;
            -webkit-animation: glow 4s ease-in-out infinite alternate;
            -moz-animation: glow 4s ease-in-out infinite alternate;
        }
    }

    @keyframes glow {
        from {
            text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #b1f3dd, 0 0 4px #b1f3dd, 0 0 5px #b1f3dd, 0 0 6px #b1f3dd, 0 0 7px #b1f3dd;
        }
        to {
            text-shadow: 0 0 2px #fff, 0 0 3px #89cff5, 0 0 4px #89cff5, 0 0 5px #89cff5, 0 0 6px #89cff5, 0 0 7px #89cff5, 0 0 8px #89cff5;
        }
    }
}