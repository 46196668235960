.desktop-navigation {
    display: block;
    position: absolute;
    top: 110px;
    left: 23%;
    font-size: 24px;
    font-family: 'Ubuntu', sans-serif;
    min-width: 720px;
    width: calc(77% - 50px);
    text-align: right;

    ul {
        display: inline-flex;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            display: inline-block;
            flex: 1;
            width: fit-content;
            float: right;

            span {
                cursor: default;
            }

            div {
                position: relative;

                ul {
                    position: absolute;
                    display: none;
                    z-index: 10;
                }
            }

            &:hover {
                background: none;

                div {
                    ul {
                        display: inline-block;
                        top: 0;
                        right: 0;
                        width: fit-content;
                        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);

                        li {
                            display: block;
                            padding: 4px;
                            width: 250px;
                        }
                    }
                }
            }
        }
    }
}

.nav-button {
    display: inline-block;
    margin: 0 auto;
    text-decoration: none;
    text-align: center;
    padding: 4px 0;
    border-radius: 4px;
    width: 100%;

    &:hover {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.mobile-navigation {
    display: none;
}

@media screen and (max-width: 960px) {

    .desktop-navigation {
        display: none;
    }

    .mobile-navigation {
        display: block;
        position: absolute;
        top: 44px;
        min-width: 160px;
        width: 100%;
        text-align: center;
        z-index: 2;

        .hamburger {
            display: inline;
            cursor: pointer;
            font-size: 24px;
            border: none;
            background: none;
            padding: 4px 10%;
            text-align: left;

            span {
                margin-left: calc(50% - 80px);
            }
        }

        ul {
            position: absolute;
            left: -40px;
            top: 34px;
            width: 100%;

            li {
                display: block;
                padding: 10px 0;
            }
        }
    }

    .nav-button {
        padding: 6px 0;
        width: calc(100% - 16px);
        border: 0;
        background: none;
        font-size: 24px;
    }
}