.fade-in {
    animation: fade-in 0.25s linear;
}

.fade-out {
    animation: fade-out 0.25s linear;
}

.fade-in-out {
    animation: fade-in-out 1.5s linear;
    opacity: 1;
}

@keyframes fade-in {
    0% { opacity: 0; visibility: hidden; }
    0.1% { opacity: 0; visibility: visible; }
    100% { opacity: 1; visibility: visible; }
}

@keyframes fade-out {
    0% { opacity: 1; visibility: visible; }
    99.9% { opacity: 0; visibility: visible; }
    100% { opacity: 0; visibility: hidden; }
}

@keyframes fade-in-out {
    0%, 100% { opacity: 0; visibility: hidden; }
    0.1%, 99.9% { opacity: 0; visibility: visible; }
    25%, 75% { opacity: 1; visibility: visible; }
}