.comic-page-view {
    position: relative;
    min-height: 600px;
    width: 70%;
    margin: 20px auto;
}

.spinner-view {
    position: absolute;
    background: lightgray;
    opacity: 50%;
    width: 100%;
    height: 100%;
    min-height: 600px;

    img {
        display: block;
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
    }
}

.comic-view {
    a {
        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}

@media screen and (max-width: 960px) {

    .comic-page-view {
        min-height: 300px;
        width: 95%;
    }
}