.media-button {
    display: block;
    position: relative;
    height: 40px;
    width: 280px;
    border: 2px lightgrey outset;
    border-radius: 8px;
    text-decoration: none;
    color: white;
    font-size: 16pt;

    &:hover {
        p {
            background-color: rgba(255, 255, 255, 0.66);
            background-clip: border-box;
            border: 0;
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
            color: black;
        }
    }

    img {
        display: inline-block;
        height: 32px;
        width: 32px;
        padding: 3px;
        background-color: white;
        border: 1px white solid;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    p {
        display: inline;
        position: absolute;
        top: -21px;
        height: 100%;
        width: calc(100% - 40px);

        span {
            position: absolute;
            top: 8px;
            padding-left: 8px;
        }
    }
}

.media-button-mini {
    display: block;
    position: relative;
    height: 24px;
    width: 24px;
    padding: 4px 20px;
    text-decoration: none;
    filter: brightness(0) invert(1);

    &:hover {
        filter: unset;
    }

    img {
        display: inline-block;
        height: 24px;
        width: 24px;
    }
}

.media-button-small {
    display: block;
    position: relative;
    height: 40px;
    width: 40px;
    padding: 4px;
    text-decoration: none;
    filter: grayscale(1);

    &:hover {
        filter: unset;
    }

    img {
        display: inline-block;
        height: 40px;
        width: 40px;
    }
}

@media screen and (max-width: 960px) {

    .media-button-mini {
        padding: 8px 16px;
        filter: unset;
    }

    .media-button-small {
        padding: 8px 16px;
        filter: unset;
    }

    .media-button-small {
        padding: 8px 16px;
        filter: unset;
    }
}