#guild-intro {
    margin: 0;
    padding: 20px;
    line-height: 1.8;
}

.guild-site-intro {
    height: unset;
    object-fit: contain;
}

.guild-group-photo {
    display: block;
    max-height: 370px;
    max-width: 90%;
    margin: 20px auto;
}

@media screen and (max-width: 960px) {

    #guild-intro {
        line-height: 1.6;
    }

    .guild-group-photo {
        display: block;
        max-width: 100%;
        margin: 8px auto;
    }
}