.chase-magic-image {
    height: 200px;
    margin: 10px 0 10px 40px;
    float: right;

    img {
        height: 200px;
    }
}

.chase-magic-text {
    min-height: 250px;
}

@media screen and (max-width: 960px) {

    .chase-magic-image {
        height: 160px;
        width: fit-content;
        margin: 10px auto;
        float: none;
        background-position-x: 50%;

        img {
            height: 160px;
        }
    }

    .chase-magic-text {
        min-height: 100px;
    }
}