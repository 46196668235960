.content-box {
    border: 0;
    background-color: transparent;

    .content {
        border: 10px solid var(--content-box-border-color, lightblue);
        border-radius: 8px;
        margin: 20px 0 40px;
        padding: 16px 5%;
    }
}

.content-scroll {
    display: block;
    margin: 20px 0 40px;
    background-color: var(--content-scroll-border-color, gold);
    border: 12px var(--content-box-color, white) solid;
    box-shadow: rgba(0, 0, 0, 0.25) -20px 1em 2em;
    object-fit: contain;
    height: unset;

    .content {
        display: inline-block;
        margin: 4px;
        height: 100%;
        width: calc(100% - 8px);
    }
}

.content {
    background-color: var(--content-box-color, white);
}

.headline {
    text-align: center;
}

@media screen and (max-width: 960px) {

    .content-box {
        border: 0 !important;

        .content {
            border: 8px solid var(--content-box-border-color, lightblue);
            border-radius: 6px;
        }
    }

    .content-scroll {
        .content {
            width: unset;
            padding: 2px;
        }
    }

    .content {
        margin: 20px 0;
    }
}