.circular-image {
    position: relative;
    z-index: 1;
    height: 150px;
    width: 150px;
    margin: 20px;
    background-color: var(--content-box-color, white);
    border: 10px solid var(--content-box-border-color, lightblue);
    border-radius: 160px;
}

.hanging-image {
    position: relative;
    z-index: 1;
    width: 150px;
    margin: 20px;
}

@media screen and (max-width: 960px) {

    .circular-image {
        position: absolute;
        z-index: 3;
        height: 70px;
        width: 70px;
        margin: 10px;
        border: 6px solid rgba(149, 210, 254, 0.5);
        border-radius: 80px;
    }

    .hanging-image {
        position: absolute;
        z-index: 3;
        width: 70px;
    }
}