.character-lineup {
    margin: 20px;
    text-align: center;

    div {
        display: inline-block;
        height: 180px;
        width: 180px;
        margin: 0 8px 60px;
        text-align: center;
    }
}

@media screen and (max-width: 960px) {

    .character-lineup {
        margin-bottom: 0;

        div {
            height: 150px;
            width: 150px;
            margin: 0 2px 32px;
        }
    }
}

@media screen and (max-width: 480px) {

    .character-lineup {

        div {
            height: 120px;
            width: 120px;
        }
    }
}