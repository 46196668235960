.share-button-container {
    height: 40px;
    width: fit-content;
    margin: 0 auto;
}

.share-button {
    display: block;
    position: relative;
    float: left;
    height: 40px;
    width: 280px;
    background-color: var(--primary-color, blue);
    border: 2px lightgrey outset;
    border-radius: 8px;
    text-decoration: none;
    color: white;
    font-size: 16pt;

    &:hover {
        p {
            background-color: rgba(255, 255, 255, 0.66);
            background-clip: border-box;
            border: 0;
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
            color: black;
        }
    }

    img {
        display: inline-block;
        height: 32px;
        width: 32px;
        margin: 0;
        padding: 3px;
        background-color: white;
        border: 1px white solid;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    p {
        display: inline;
        position: absolute;
        min-height: 40px;
        height: 100%;
        width: calc(100% - 40px);
        margin: 0;

        span {
            position: absolute;
            top: 8px;
            padding-left: 8px;
        }
    }
}

.share-domain {
    height: 40px;
    width: 400px;
    margin: 0 0 0 8px;
    font-size: 32px;
    vertical-align: middle;
}

@media screen and (max-width: 960px) {
    .share-button-container {
        width: 100%;
        margin: 0;
    }

    .share-button {
        float: none;
    }

    .share-domain {
        width: 95%;
        margin: 8px auto;
    }
}