#header {
    height: 150px;
    min-width: 960px;

    .user-data {
        position: absolute;
        top: 12px;
        right: 12px;
    }
}

@media screen and (max-width: 960px) {

    #header {
        height: 100px;
        min-width: 320px;

        .user-data {
            top: 6px;
            right: 6px;
        }
    }
}