.charity-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
        flex: 1;
        padding: 36px 36px 60px;

        * {
            margin: 0 auto;
            text-align: center;
        }

        img {
            max-width: 150px;
        }
    }
}

.donations-content-height {
    min-height: 1100px;
}

@media screen and (max-width: 960px) {

    .charity-list {
        display: block;
        padding-left: 0;

        li {
            height: 250px;
            padding: 0;
        }
    }

    .donations-content-height {
        min-height: 1800px;
    }
}