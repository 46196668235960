.qr-intro {
    margin: 0 270px 0 20px;
    line-height: 1.8;
}

.qr-media-buttons {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 50%;
    padding-left: 0;
    margin: 8px auto;

    li {
        flex: 1;
        margin: 4px;

        a {
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: 960px) {

    .qr-intro {
        margin: 20px;
        line-height: 1.6;
    }

    .qr-media-buttons {
        width: 100%;

        li {
            margin: 4px 5%;
        }
    }
}