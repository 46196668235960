.character-info {
    margin: 0 20px;
    min-height: 750px;
}

.character-image-right {
    height: 240px;
    float: right;

    img {
        height: inherit;
    }
}

.character-image-left {
    background-size: 250px;
    background-repeat: no-repeat;
    height: 240px;
    margin: 10px 6px 10px 0;
    float: left;

    img {
        height: inherit;
    }
}

@media screen and (max-width: 960px) {

    .character-image-right {
        float: none;
        height: 180px;
        width: 100%;
        text-align: center;

        img {
            display: block;
            margin: 0 auto;
        }
    }

    .character-image-left {
        float: none;
        background-size: 180px;
        height: 180px;
        width: 100%;
        margin: 4px 12px;
        text-align: center;

        img {
            display: block;
            margin: 0 auto;
        }
    }
}