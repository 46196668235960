.common-word {
    display: inline;
    position: relative;
    height: 24px;
    width: fit-content;
}

.common-word-character {
    display: inline;
    position: relative;
    height: 24px;
    vertical-align: bottom;
}

.common-word-modifier {
    display: inline;
    position: absolute;
    bottom: 0;
    height: 24px;
    user-select: none;
    vertical-align: bottom;
}

[data-theme="dark"] {
    .common-word-character {
        filter: invert(0.8);
    }

    .common-word-modifier {
        filter: invert(0.8);
    }
}