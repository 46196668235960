.badge-showcase {
    margin: 20px auto;
    width: fit-content;
}

.badges-box-border {
    display: inline-block;
    background-color: #772b00;
    border: 12px solid burlywood;

    div {
        display: block;
        height: 80px;
        width: 80px;
        padding: 20px 0 0 20px;
    }
}

.badges-auxiliary-1 {
    float: left;
    border-right: 0;
}

.badges-auxiliary-2 {
    float: right;
    border-left: 0;
}

.badges-main {
    .badge-row {
        display: block;
        height: 100px;
        width: 400px;
        padding: 0;

        div {
            display: inline-block;
        }
    }
}

.badge-empty-slot {
    display: inline-block;
    height: 56px;
    width: 56px;
    background: rgba(0, 0, 0, 0.25);
    border: 2px solid rgba(25, 25, 25, 0.25);
    border-radius: 60px;
    z-index: 1;
}

.badge-hidden-slot {
    display: inline-block;
    height: 60px;
    width: 60px;
}

.fire-to-frost {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.wind-to-water {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.fire-to-lightning {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        bottom: 50%;
        left: 50%;
        height: 100%;
        width: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.water-to-earth {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        bottom: 50%;
        left: 50%;
        height: 100%;
        width: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.space-to-time {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.light-to-dark {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 50%;
        right: 50%;
        width: 100%;
        height: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.time-to-dark {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        height: 100%;
        width: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.space-to-light {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        bottom: 50%;
        left: 50%;
        height: 100%;
        width: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.nature-to-spirit {
     position: relative;

     &::before {
         position: absolute;
         content: '';
         top: 50%;
         left: 50%;
         width: 100%;
         height: 4px;
         background-color: rgba(0, 0, 0, 0.5);
     }
 }

@media screen and (max-width: 960px) {

    .badges-box-border {
        border: 8px solid burlywood;
        margin: 0 auto;
        width: fit-content;

        div {
            display: inline-block;
            height: 60px;
            width: 60px;
            padding: 10px;
        }
    }

    .badges-auxiliary-1 {
        display: block;
        float: unset;
        border-right: 8px solid burlywood;
        border-bottom: 0;
    }

    .badges-auxiliary-2 {
        display: block;
        float: unset;
        border-left: 8px solid burlywood;
        border-top: 0;
    }

    .badges-main {
        .badge-row {
            display: block;
            height: 80px;
            width: fit-content;
            padding: 0;
        }
    }
}