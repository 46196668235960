.contact-content {
    position: relative;
    min-height: 280px;
    padding: 10px 20px;
}

.contact-right {
    margin: 0 auto;
    width: fit-content;

    img {
        height: 200px;
        margin: 10px 0;
        text-align: center;
    }
}

.contact-image {
    height: 20px;
    padding-right: 6px;
}

.contact-table {
    margin: 0 auto;
}

@media screen and (max-width: 960px) {

    .contact-content {
        min-height: 200px;
    }
}