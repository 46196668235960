.stream-link {
    display: block;
    margin: 24px auto;
    border: transparent 2px solid;
    height: 200px;
    width: 200px;

    &:hover {
        border: #ff7921 2px solid;
    }

    img {
        height: 100%;
    }
}

.stream-about {
    object-fit: contain;
    margin: 0 auto;
    width: 95%;
    text-align: center;
}

@media screen and (max-width: 960px) {

}