.comic-header {
    display: block;
    height: 160px;
    margin: 0 auto;
}

.character-pic {
    height: 250px;
    margin: 20px auto;
    display: block;
}

.talechasers-preview {
    width: 70%;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
}

@media screen and (max-width: 960px) {

    .comic-header {
        height: auto;
        width: 60%;
        margin: 8px auto;
    }

    .character-pic {
        height: 180px;
    }

    .talechasers-preview {
        width: 95%;
        padding: 10px 32px 10px 4px;
    }
}
