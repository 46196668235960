.image-link {
    height: 150px;
    width: 150px;
    margin: 0 2px 32px;

    a {
        &:hover {
            font-weight: bold;

            img {
                border: 2px solid var(--link-text, blue);
            }
        }

        img {
            display: block;
            height: 100%;
            border: transparent 2px solid;
            object-fit: contain;
            margin-bottom: 12px;
        }
    }
}

.bordered-image-link {
    border: 2px solid var(--link-text, blue) !important;
    border-radius: 1px;
}

@media screen and (max-width: 960px) {

    .image-link {
        margin-bottom: 8px;

        a {
            img {
                margin-bottom: 8px;
            }
        }
    }
}