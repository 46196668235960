.page-500 {
    text-align: center;
}

.side-text {
    padding: 30px 250px 0 30px;
}

.silhouette-error {
    min-height: 400px;
    background-size: 225px;
    background-image: url("https://cdn.chasethefox.com/m/chase/assets/chase_eep.png");
    background-repeat: no-repeat;
    background-position-x: 92.5%;
    background-position-y: 100%;
}

@media screen and (max-width: 960px) {
    .silhouette {
        height: 240px;
    }
}