.guild-contact {
    position: relative;
    min-height: 300px;

    div:not(.character-image) {
        padding: 20px;
    }
}

@media screen and (max-width: 960px) {

    .guild-contact {
        min-height: 240px;
    }
}