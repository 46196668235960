#footer {
    height: 120px;
    width: 100%;
    border-top: 2px var(--edge-color, white) ridge;
    min-width: 960px;
}

#footer-media-buttons {
    width: fit-content;
    margin: 0 auto;
    vertical-align: bottom;

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        width: 100%;
        padding-left: 0;
        padding-right: 30%;
        margin: 8px auto 0;

        li {
            flex: 1;
            margin: 4px;

            a {
                margin: 0 auto;
            }
        }
    }
}

#footer-top-button {
    width: fit-content;
    margin: 0 auto;

    button {
        display: block;
        padding: 0;
        border: none;
        background-color: transparent;
        color: var(--secondary-text, white);
        font-size: 16pt;
        cursor: pointer;

        &:hover {
            color: var(--link-text-hover, lightblue);
        }
    }
}

#footer-credits {
    width: fit-content;
    margin: 0 auto;
    text-align: center;

    a {
        color: var(--secondary-text, white);

        &:hover {
            color: var(--link-text-hover, lightblue);
        }
    }
}

@media screen and (max-width: 960px) {

    #footer {
        height: 250px;
        min-width: 320px;
        text-align: center;
    }

    #footer-top-button {
        button {
            margin: 20px auto 0;
        }
    }

    #footer-media-buttons {
        bottom: 0;
        width: 80%;

        ul {
            width: 100%;
            padding-right: 0;

            li {
                margin: 4px 5%;
            }
        }
    }

    #footer-credits {
        float: none;
        width: 100%;
        margin: 10px auto;
    }

    button {
        margin: 20px auto 0;
    }
}