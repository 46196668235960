.form-body {
    width: fit-content;
    border: 1px solid black;
    border-radius: 4px;
    margin: 12px auto;
    padding: 8px;
}

.form-warning {
    color: red;
}

label {
    min-font-size: 16pt;
    padding-left: 4px;
    padding-right: 12px;
}

input {
    min-font-size: 16pt;
    margin-left: 4px;
    margin-right: 12px;
}

@media screen and (max-width: 960px) {

    .form-body {
        width: 90%;
        margin: 6px auto;
    }

    label {
        padding-right: 12px;
    }

    input {
        margin-left: 4px;
    }
}