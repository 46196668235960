.fps-logo {
    display: block;
    margin: 0 auto;
    width: 120px;
}

.shop-item {
    height: 240px;
    text-align: center;
    margin-bottom: 0;

    img {
        float: left;
        max-height: 200px;
        width: 240px;
        margin: 12px 40px;
    }

    p {
        display: block;
        margin: 0 5% 0 300px;
        max-width: 70%;
    }

    a {
        display: block;
        margin: 16px 5% 0 300px;
        max-width: 70%;
    }
}

.shop-items-loading {
    height: 360px;
    width: fit-content;
    text-align: center;
    margin: 0 auto;

    img {
        display: block;
        height: 120px;
        width: 120px;
    }
}

@media screen and (max-width: 960px) {
    .fps-logo {
        width: 80px;
    }

    .shop-item {
        height: fit-content;
        margin-bottom: 40px;

        img {
            float: unset;
            max-height: unset;
            width: 90%;
            margin: 16px;
        }

        p {
            margin: 0 auto;
            max-width: 100%;
        }

        a {
            margin: 16px auto;
            max-width: 100%;
        }
    }
}