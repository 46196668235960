.sticker-content {
    p {
        padding: 0 12px;
    }
}

.sticker-pack-link {
    display: block;
    margin: 24px auto;
    border: 2px solid var(--link-text, blue);
    height: 480px;
    width: 480px;

    &:hover {
        border: 2px solid var(--link-text-hover, lightblue);
    }

    img {
        height: 480px;
    }
}

.sticker-packs-legacy {
    display: flex;

    a {
        flex: 1;
        text-align: center;
    }
}

@media screen and (max-width: 960px) {

    .sticker-pack-link {
        max-height: 300px;
        max-width: 300px;
        width: 90%;

        img {
            max-height: 300px;
            max-width: 300px;
            width: 100%;
        }
    }
}