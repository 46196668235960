.language-opener-image {
    display: block;
    margin: 0 auto;
    height: 360px;
    width: 360px;
}

@media screen and (max-width: 960px) {
    .language-opener-image {
        height: 240px;
        width: 240px;
    }
}