.tf-gen-result {
    height: 550px;
}

.result-waiting {
    height: fit-content;

    img {
        display: block;
        margin: 10px auto;
        height: 400px;
    }
}

.result-display {
    min-height: 400px;
    height: fit-content;

    div {
        .result-message {
            min-height: 60px;
            width: 90%;
            margin: 6px auto;
        }

        .result-tweet {
            height: fit-content;
            width: fit-content;
            margin: 0 auto;
            padding-left: 0;
            list-style: none;

            li {
                padding: 8px 0;
            }
        }
    }
}

.result-image {
    display: block;
    height: 200px;
    margin: 60px auto 6px;
}

@media screen and (max-width: 960px) {

    .tf-gen-result {
        height: 850px;
    }

    .result-waiting {
        img {
            margin: auto;
            height: fit-content;
            width: 90%;
        }
    }

    .result-display {
        div {
            .result-tweet {
                li {
                    padding: 16px 0;
                }
            }
        }
    }
}