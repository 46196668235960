.main-body {
    min-height: calc(100vh - 242px);
    min-width: 900px;
    width: 100%;
    background: var(--background-color, lightblue);
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: repeat var(--background-image);
        opacity: var(--background-image-opacity, 0.5);
    }

    .content-body {
        position: relative;
        min-width: 750px;
        padding: 4px 0;
        margin: 0 160px;
        background: none;
        opacity: 1;
    }
}

@media screen and (max-width: 960px) {

    .main-body {
        min-height: calc(100vh - 252px);
        min-width: 320px;

        .content-body {
            min-width: 320px;
            margin: 0 20px;
        }
    }
}